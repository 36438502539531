import React from "react"
import { Link } from "gatsby"

const GithubPage = () => (
    <div>
      <h1>Hi people</h1>

    </div>
)

export default GithubPage
